import fetch from '@/utils/fetch';

// For /publicare/adaugare
export function getCategories() {
	return fetch({
		url: '/import/getsuppliercategories',
		method: 'post'
	});
}

export function getCategCharacts(data) {
	return fetch({
		url: '/products/getCategoryCharacteristics',
		method: 'post',
		data: data
	});
}

export function saveProduct(data) {
	return fetch({
		url: '/products/addProduct',
		method: 'post',
		data: data
	});
}

export function getLiveProductsCategory() {
	return fetch({
		url: '/products/getLiveProductsCategory',
		method: 'post'
	});
}

// For /publicare/oferta

export function getLiveProductsFromCategory(data) {
	return fetch({
		url: '/products/getLiveProductsFromCategory',
		method: 'post',
		data: data
	});
}

export function addOfferToExistingProduct(data) {
	return fetch({
		url: '/products/addOfferToExistingProduct',
		method: 'post',
		data: data
	});
}

// For /publicare/import

export function getImportHistory(data) {
	return fetch({
		url: '/admininfo/getImportHistory',
		method: 'post',
		data: data
	});
}

export function getImportModel(data) {
	return fetch({
		url: '/example/getExample',
		method: 'post',
		data: data
	});
}

export function importFile(data) {
	return fetch({
		url: '/import/uploadFile',
		method: 'post',
		data: data
	});
}

export function getHistoryDetails(data) {
	return fetch({
		url: '/import/getImportHistoryDetails',
		method: 'post',
		data: data
	});
}


export function getCelOffers(data) {
	return fetch({
		url: '/import/getCelOffers',
		method: 'post',
		data
	});
}

// For /publicare/feeduri
export function saveFeed(data) {
	return fetch({
		url: '/feeds/saveFeed',
		method: 'post',
		data: data
	});
}

export function getFeeds(data) {
	return fetch({
		url: '/feeds/getFeeds',
		method: 'post',
		data: data
	});
}

export function deleteFeed(data) {
	return fetch({
		url: '/feeds/deleteFeed',
		method: 'post',
		data: data
	});
}

export function validateFeedGeneralSettings(data) {
	return fetch({
		url: '/feeds/validateFeedGeneralSettings',
		method: 'post',
		data: data
	});
}

export function validateFeedLink(data) {
	return fetch({
		url: '/feeds/validateFeedLink',
		method: 'post',
		data: data
	});
}

export function getFeedDetails(data) {
	return fetch({
		url: '/feeds/getFeedDetails',
		method: 'post',
		data: data
	});
}

export function parseFeedFile(data) {
	return fetch({
		url: '/feeds/parseFeedFile',
		method: 'post',
		data: data
	});
}

export function saveAssocFields(data) {
	return fetch({
		url: '/feeds/saveAssocFields',
		method: 'post',
		data: data
	});
}

export function getColumnAssoc(data) {
	return fetch({
		url: '/feeds/getColumnAssoc',
		method: 'post',
		data: data
	});
}

export function saveCategoryAssoc(data) {
	return fetch({
		url: '/feeds/saveCategoryAssoc',
		method: 'post',
		data: data
	});
}

export function saveRunTimePrefs(data) {
	return fetch({
		url: '/feeds/saveRunTimePrefs',
		method: 'post',
		data: data
	});
}

export function changeFeedStatus(data) {
	return fetch({
		url: '/feeds/changeFeedStatus',
		method: 'post',
		data: data
	});
}

export function resetFieldAssoc(data) {
	return fetch({
		url: '/feeds/resetFieldAssoc',
		method: 'post',
		data: data
	});
}

export function runFeed(data) {
	return fetch({
		url: '/feeds/runFeed',
		method: 'post',
		data: data
	});
}

export function getFeedHistory(data) {
	return fetch({
		url: '/feeds/getFeedHistory',
		method: 'post',
		data: data
	});
}

export function viewFeedHistoryDetails(data) {
	return fetch({
		url: '/feeds/viewFeedHistoryDetails',
		method: 'post',
		data: data
	});
}

export function getBonusModel() {
	return fetch({
		url: '/example/getBonusModel',
		method: 'post',
	});
}

export function importBonus(data) {
	return fetch({
		url: '/import/importBonus',
		method: 'post',
		data: data
	});
}