<template>
	<div class="app-container">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('publish.offer.add-header')}}</span>
			</div>
			<div class="categorySelection">
				<div class="title">
					1. {{$t('publish.offer.step-1')}}
				</div>
				<div>
					<c-select @selected="categorySelected"></c-select>
				</div>
			</div>
			<div class="separator"></div>
			<div class="productSelection">
				<div class="title">
					2. {{$t('publish.offer.step-2')}}
				</div>
				<div>
					<el-input v-model="searchTerm" class="input-with-select"
						:placeholder="$t('publish.offer.search-ph')" @keyup.enter.native="searchProduct">
						<el-button slot="append" icon="el-icon-search" @click.native="searchProduct"></el-button>
					</el-input>
				</div>
				<div v-if="products">
					<div class="separator"></div>
					<div class="center">
						<el-pagination layout="prev, pager, next, total" :current-page="currentPage" :page-size="limit"
							:total="productsCount" @current-change="changePage">
						</el-pagination>
						<div class="separator"></div>
					</div>
					<el-table :data="products" stripe style="width: 100%" border>
						<el-table-column :label="$t('general.name')">
							<template slot-scope="scope">
								<a target="_blank" :href="getAddress(scope.row)">{{scope.row.products_name}}</a>
							</template>
						</el-table-column>
						<el-table-column prop="products_model" width="180px" :label="$t('publish.offer.product-model')">
						</el-table-column>
						<el-table-column prop="pretf" width="160px" :label="$t('publish.offer.pretf')">
						</el-table-column>
						<el-table-column :label="$t('general.action')" width="130px" fixed="right">
							<template slot-scope="scope">
								<el-button type="primary" size="small" icon="el-icon-circle-check-outline"
									@click.native="selectProduct(scope.row)" plain>{{$t('publish.offer.select')}}
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="center">
						<div class="separator"></div>
						<el-pagination layout="prev, pager, next, total" :current-page="currentPage" :page-size="limit"
							:total="productsCount" @current-change="changePage">
						</el-pagination>
					</div>
				</div>
			</div>
			<div id="productDetails" class="separator"></div>
			<div v-if="selectedProduct">
				<div class="title">
					3. {{$t('publish.offer.step-3')}}
					<strong>{{selectedProduct.products_name}}</strong>
				</div>
				<div>
					<div class="sub-title">{{$t('publish.offer.product-model')}}</div>
					<el-input placeholder="Ex: 8237183" v-model="newProduct.products_model"></el-input>
					<div class="separator"></div>
					<div class="sub-title">{{$t('publish.offer.pretf2')}}</div>
					<el-input :placeholder="setPricePlaceholder" v-model="newProduct.price"></el-input>
					<div class="separator"></div>
					<div class="sub-title">{{$t('publish.offer.stock')}}</div>
					<el-input v-model="newProduct.stoc"></el-input>
					<div class="separator"></div>
					<el-button type="primary" size="small" icon="el-icon-check" @click.native="saveProduct(false)"
						plain>{{$t('general.save')}}</el-button>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		getLiveProductsFromCategory,
		addOfferToExistingProduct
	} from '@/api/publicare';

	import {
		debugPromise
	} from '@/utils/index';
	import {
		EventBus
	} from '@/utils/Bus.js';
	const Box = () =>
		import('vue-info-box-element');
	const CategorySelect = () =>
		import('@/components/CategoriesSelect');
	export default {
		components: {
			Box,
			'c-select': CategorySelect
		},
		data() {
			return {
				categories: [],
				selectedCategory: 0,
				start: 0,
				limit: 20,
				productsCount: 0,
				searchTerm: '',
				products: false,
				selectedProduct: false,
				currentPage: 1,
				newProduct: {
					price: '',
					stoc: '',
					products_model: '',
				}
			};
		},
		computed: {
			setPricePlaceholder() {
				return this.$t('publish.offer.current-price') + ': ' + this.selectedProduct.pretf + ' RON';
			},
		},
		methods: {
			changePage(page) {
				this.start = page * this.limit - this.limit;
				this.currentPage = page;
				this.getProductDetails();
			},
			saveProduct(overridePrice) {
				if (this.newProduct.price < 1 || this.newProduct.stoc.length < 1 || this.newProduct.stoc < 1) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('publish.offer.required-price'),
					});
					return false;
				}
				const params = {
					products_model: btoa(this.selectedProduct.products_model),
					stoc: this.newProduct.stoc,
					pret: this.newProduct.price,
					model: this.newProduct.products_model
				};
				if (overridePrice) params.overridePrice = 1;
				addOfferToExistingProduct(params).then((res) => {
					if ('undefined' == typeof res) {
						this.$router.push({
							name: 'Profil public'
						}).catch(() => {});
						return;
					}
					switch (true) {
						case parseInt(res.error) === 3:
							this.$confirm(res.message + '. ' + this.$t('publish.offer.confirm-offer'), this.$t(
								'general.warning'), {
								confirmButtonText: this.$t('general.yes'),
								cancelButtonText: this.$t('general.no'),
								type: 'warning'
							}).then(() => {
								this.saveProduct(true);
							}).catch((e) => {
								debugPromise('addOfferToExistingProduct-confirm', e);
							});
							return false;

						case parseInt(res.error) === 1:
							this.sbMsg({
								type: 'warn',
								message: res.message,
							});
							return false;

						default:
							this.resetFields();
							return false;
					}
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'addOfferToExistingProduct',
							params
						}
					});
					debugPromise('addOfferToExistingProduct', e);
				});
			},
			handleCategoryChange(val) {
				this.getProductDetails();
			},
			resetFields() {
				this.categories = [];
				this.selectedCategory = 0;
				this.searchTerm = '';
				this.start = 0;
				this.productsCount = 0;
				this.products = false;
				this.selectedProduct = false;
				this.currentPage = 1;
				this.newProduct = {
					price: '',
					stoc: '',
					products_model: ''
				};
			},
			getProductDetails() {
				const params = {
					category: this.selectedCategory,
					start: this.start,
					limit: this.limit
				};
				if (this.searchTerm.length > 1) params.keyword = this.searchTerm;
				getLiveProductsFromCategory(params).then((res) => {
					if (typeof res === 'undefined') return false;
					if (typeof res.message === 'undefined') return false;
					this.products = res.message.product_list;
					this.productsCount = parseInt(res.message.product_count);
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'addOfferToExistingProduct',
							params
						}
					});
					debugPromise('getLiveProductsFromCategory', e);
				});
			},
			searchProduct() {
				if (this.selectedCategory < 1 || typeof this.selectedCategory === 'undefined') {
					this.sbMsg({
						type: 'warn',
						message: 'Nu ai selectat categoria',
					});
					return false;
				}

				this.start = 0;

				this.getProductDetails();
			},
			getAddress(row) {
				return 'http://' + row.url;
			},
			selectProduct(row) {
				this.selectedProduct = row;
				this.newProduct.products_model = this.selectedProduct.products_model;
				document.getElementById('productDetails').scrollIntoView(true);
			},
			categorySelected(categ) {
				if (typeof categ.categories_id !== 'undefined') {
					this.selectedCategory = categ.categories_id;
					if (parseInt(categ.categories_id) !== 0) {
						this.getProductDetails();
					}
				}
			}
		},
		created() {},
		beforeDestroy() {
			EventBus.$off();
		},
	};
</script>